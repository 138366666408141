body {
  height: 100vh !important;
  color: #333333;
}
.logo {
  margin-left: 1rem;
}

.logo-img {
  height: 32px;
  width: 167px;
}

.btn-primary {
  color: #fff !important;
}

.logo {
  width: 125px;
}

.main-content {
  padding-top: 80px;
}

.message-box {
  padding: 5px 24px;
  border-top: 1px solid #867bcd;
  border-bottom: 1px solid #867bcd;
  margin-top: 20px;
  background-color: rgba(26, 21, 53, 0.4);
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #eee;
  border: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}
.well-large {
  padding: 24px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.well-small {
  padding: 9px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.custom-link {
  color: var(--bs-link-color) !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

a:hover {
  color: var(--bs-link-hover-color);
  text-decoration: underline;
  cursor: pointer;
}
