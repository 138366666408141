/*!
 * Author - Ruchi Sinha
 * These values are configurable variables from the _variables.scss file, over written by this file.
 * This file is named _bootstrap-overrides.scss - the leading underscore stops SASS from compiling this file.
 * When starting a new theme - it might be best to refresh the variables with the default values from the 
 * Bootstrap _variables.scss file.
 */

// Import web fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
// Overrides next!
//
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #f1f0ed !default; //changed to client color - used for form background
$gray-500: #adb5bd !default;
$gray-600: #c3c2c2 !default;
$gray-700: #b6b3a7 !default; //changed to client color - used for form field borders
$gray-800: #464646 !default;
$gray-900: #535362 !default;
//
//
$black: #000 !default;
$blue: #0082c4 !default;
$indigo: #25358e !default;
$purple: #686da2 !default;
$pink: #ee6478 !default;
$red: #ed1a3b !default;
$orange: #f3992e !default;
$yellow: #ffcd05 !default;
$green: #00ab4e !default;
$teal: #00ad7d !default;
$cyan: #17a2b8 !default;
//
//
$primary: $teal !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $blue !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
//
//
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-print-styles: true !default;
$enable-responsive-font-sizes: false !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;
//
//
$body-bg: $white !default;
$body-color: $black !default;
//
//
$link-color: $primary !default;
$link-decoration: underline !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;
//
// Radius
$border-radius: 0.4rem !default;
$border-radius-lg: 0.4.4rem !default;
$border-radius-sm: 0.1rem !default;
//
//
// Typography
$font-family-sans-serif-body: Arial, "Helvetica Neue", Helvetica, sans-serif !default;
$font-family-sans-serif: Arial, "Helvetica Neue", Helvetica, sans-serif !default;
$font-family-sans-serif-buttons: Arial, "Helvetica Neue", Helvetica, sans-serif !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;
//
//
$font-family-base: $font-family-sans-serif-body !default;
$font-size-base: 0.8rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;
//
//
$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.6 !default;
$h3-font-size: $font-size-base * 1.4 !default;
$h4-font-size: $font-size-base * 1.3 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
//
//
$headings-font-family: $font-family-sans-serif-body !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
//
//
$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-font-family: $font-family-sans-serif-buttons !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;
//

$nav-link-hover-color: lighten($teal, 10%);
$nav-link-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

$carousel-control-color: $white;
$carousel-indicator-active-bg: $teal;
$carousel-control-width: 7%;
$carousel-control-icon-width: 1rem;

$modal-fade-transform: scale(0.9);

$enable-negative-margins: true;
